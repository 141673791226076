// Mixins
// --------------------------------------------------

// Utilities
@import "mixins/hide-text.less";
@import "mixins/opacity.less";
@import "mixins/image.less";
@import "mixins/labels.less";
@import "mixins/reset-filter.less";
@import "mixins/resize.less";
@import "mixins/responsive-visibility.less";
@import "mixins/size.less";
@import "mixins/tab-focus.less";
@import "mixins/reset-text.less";
@import "mixins/text-emphasis.less";
@import "mixins/text-overflow.less";
@import "mixins/vendor-prefixes.less";

// Components
@import "mixins/alerts.less";
@import "mixins/buttons.less";
@import "mixins/panels.less";
@import "mixins/pagination.less";
@import "mixins/list-group.less";
@import "mixins/nav-divider.less";
@import "mixins/forms.less";
@import "mixins/progress-bar.less";
@import "mixins/table-row.less";

// Skins
@import "mixins/background-variant.less";
@import "mixins/border-radius.less";
@import "mixins/gradients.less";

// Layout
@import "mixins/clearfix.less";
@import "mixins/center-block.less";
@import "mixins/nav-vertical-align.less";
@import "mixins/grid-framework.less";
@import "mixins/grid.less";


.text-shadow (@string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: @string;
}
.box-shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow:    @string;
  box-shadow:         @string;
}
.drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:		@x @y @blur @spread rgba(0, 0, 0, @alpha);
}
.inner-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:    inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:         inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.box-sizing (@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing:    @type;
  box-sizing:         @type;
}

.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius:    @radius;
  border-radius:         @radius;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}
.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  -webkit-border-top-right-radius:    @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius:  @bottomleft;
  -webkit-border-top-left-radius:     @topleft;

  -moz-border-radius-topright:        @topright;
  -moz-border-radius-bottomright:     @bottomright;
  -moz-border-radius-bottomleft:      @bottomleft;
  -moz-border-radius-topleft:         @topleft;

  border-top-right-radius:            @topright;
  border-bottom-right-radius:         @bottomright;
  border-bottom-left-radius:          @bottomleft;
  border-top-left-radius:             @topleft;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

.opacity (@opacity: 0.5) {
  -webkit-opacity: 	@opacity;
  -moz-opacity: 		@opacity;
  opacity: 		@opacity;
}

.gradient (@startColor: #eee, @endColor: white) {
  background-color: @startColor;
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(top, @startColor, @endColor);
  background: -moz-linear-gradient(top, @startColor, @endColor);
  background: -ms-linear-gradient(top, @startColor, @endColor);
  background: -o-linear-gradient(top, @startColor, @endColor);
}
.horizontal-gradient (@startColor: #eee, @endColor: white) {
  background-color: @startColor;
  background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
  background-image: -webkit-linear-gradient(left, @startColor, @endColor);
  background-image: -moz-linear-gradient(left, @startColor, @endColor);
  background-image: -ms-linear-gradient(left, @startColor, @endColor);
  background-image: -o-linear-gradient(left, @startColor, @endColor);
}

.animation (@name, @duration: 300ms, @delay: 0, @ease: ease) {
  -webkit-animation: @name @duration @delay @ease;
  -moz-animation:    @name @duration @delay @ease;
  -ms-animation:     @name @duration @delay @ease;
}

.transition (@transition) {
  -webkit-transition: @transition;
  -moz-transition:    @transition;
  -ms-transition:     @transition;
  -o-transition:      @transition;
}
.transform(@string){
  -webkit-transform: @string;
  -moz-transform: 	 @string;
  -ms-transform: 		 @string;
  -o-transform: 		 @string;
}
.scale (@factor) {
  -webkit-transform: scale(@factor);
  -moz-transform: 	 scale(@factor);
  -ms-transform: 		 scale(@factor);
  -o-transform: 		 scale(@factor);
}
.rotate (@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: 	 rotate(@deg);
  -ms-transform: 		 rotate(@deg);
  -o-transform: 		 rotate(@deg);
}
.skew (@deg, @deg2) {
  -webkit-transform:       skew(@deg, @deg2);
  -moz-transform: 	 skew(@deg, @deg2);
  -ms-transform: 		 skew(@deg, @deg2);
  -o-transform: 		 skew(@deg, @deg2);
}
.translate (@x, @y:0) {
  -webkit-transform:       translate(@x, @y);
  -moz-transform: 	 translate(@x, @y);
  -ms-transform: 		 translate(@x, @y);
  -o-transform: 		 translate(@x, @y);
}
.translate3d (@x, @y: 0, @z: 0) {
  -webkit-transform:       translate3d(@x, @y, @z);
  -moz-transform: 	 translate3d(@x, @y, @z);
  -ms-transform: 		 translate3d(@x, @y, @z);
  -o-transform: 		 translate3d(@x, @y, @z);
}
.perspective (@value: 1000) {
  -webkit-perspective: 	@value;
  -moz-perspective: 	@value;
  -ms-perspective: 	@value;
  perspective: 		@value;
}
.transform-origin (@x:center, @y:center) {
  -webkit-transform-origin: @x @y;
  -moz-transform-origin:    @x @y;
  -ms-transform-origin:     @x @y;
  -o-transform-origin:      @x @y;
}
.overlay (@opacity: 0.5) {
  background-color:rgba(0,0,0,@opacity);
  height:100%;
  width:100%;
}

.loader (@color, @color2: white, @size: 50px) {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: @size;
  height: @size;
  text-indent:-20000px;
  overflow:hidden;
  background-color: @color;
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    border: 0px solid @color2;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: loading 1000ms linear forwards infinite;
    animation: loading 1000ms linear forwards infinite;
  }

  @-webkit-keyframes loading {
    0% {
      border: 0px solid @color2;
    }
    50% {
      border: 16px solid @color2;
      width: 0%;
      height: 0%;
    }
    100% {
      border: 0px solid @color2;
    }
  }

  /*
      @keyframes loading {
        0% {
          border: 0px solid @color2;
        }
        20% {
          border: 8px solid @color2;
          width: 0%;
          height: 0%;
        }
        50% {
          border: 8px solid @color2;
          width: 100%;
          height: 100%;
        }
        80% {
          border: 8px solid @color2;
          width: 0%;
          height: 0%;
        }
        100% {
          border: 0px solid @color2;
        }
      }
      */

}

.responsive(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}

.responsiveMin(@minWidth; @rules) {
  @media only screen and (min-width: @minWidth) {
    @rules();
  }
}

@smartphone-width: 767px;