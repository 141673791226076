@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.replace_text_width_image(@url, @width, @height) {
  width: @width;
  height:@height;
  display:inline-block;
  overflow:hidden;
  text-indent:-2000px;
  background-image:url(@url);
  background-repeat: no-repeat;
  background-size: @width @height;
  vertical-align: middle;
}

.hero_background(@url) {
  background-size:cover;
  background-image:url(@url);
  background-position:center;
}

.square(@size){
  width:@size;
  height:@size;
}

.circle(@size){
  width:@size;
  height:@size;
  .border-radius(@size/2*1.5)
}

.padding-left-right(@px) {
  padding-left:@px;
  padding-right:@px;
}

.vertical-middle{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.text-dark-opacity(@opacity:0.7){
  color:rgba(0,0,0,@opacity)
}
.text-white-opacity(@opacity:0.7){
  color:rgba(255,255,255,@opacity)
}

.opacity-background-color(@hex, @opacity){
  background-color: rgba(red(@hex), green(@hex), blue(@hex), @opacity);
}

.arrow-up(@width, @height, @color) {
  width: 0;
  height: 0;
  border-left: @width/2 solid transparent;
  border-right: @width/2 solid transparent;
  border-bottom: @height solid @color;
}

.arrow-down(@width,@height, @color) {
  width: 0;
  height: 0;
  border-left: @width/2 solid transparent;
  border-right: @width/2 solid transparent;
  border-top: @height solid @color;
}

.arrow-right(@width,@height,@color){
  width: 0;
  height: 0;
  border-top: @height/2 solid transparent;
  border-bottom: @height/2 solid transparent;
  border-left: @width solid @color;
}

.arrow-left(@width, @height, @color){
  width: 0;
  height: 0;
  border-top:@height/2 solid transparent;
  border-bottom: @height/2 solid transparent;
  border-right:@width solid @color;
}