@import 'bootstrap/bootstrap';
@import 'utils';
@import './form.less';
:global {

  html {
    height: 100%;
    width: 100%;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .error-message {
    padding: 4px 0;
    text-align: center;
    margin-bottom: 8px;
    color: @brand-danger;
  }

  .alert-toast {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
  }

  #code-input {
    text-align: center;
    width: 100%;
    height: 54px;
    border: solid 1px #ccc;
    padding: 1px 2px;
    font-size: 20px;
    color: #525461;
    box-sizing: border-box;
    border-radius: 4px;
    -webkit-appearance: initial;
    margin-right: -3px;
    position: relative;
    z-index:1;
    &:focus {
      z-index: 2;
      outline-color: lighten(@brand-primary, 10%);
    }
  }

  .alert-toast-wrap {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: @brand-primary;
    background-color: #fff;
    max-width: 480px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    border-radius: 4px;
    padding: 6px;
  }

  .tip {
    font-size: 13px;
    color: #999;
  }

  .forgot {
    position: absolute;
    right: 4px;
    padding: 12px 12px;
    color: #999;
  }

  .btn-sign-in {
    width: 240px;
  }

  .btn {
    outline: none;
    &:disabled {
       opacity: .5;
    }
  }

  body {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #F3F3F7;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    &.table {
      display: table;
      width: 100%;
      #app {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .auth-panel {
    background-color: #fff;
    .border-radius(8px);
    padding: 48px;
    margin: 0 auto;
    width: 100%;
    max-width: 480px;
    box-shadow: 0 30px 40px rgba(0,0,0,.2);

    .responsive(@screen-tablet, { margin-top: 0; padding: 24px; });

    .panel-header {
      color: #666;
      margin-bottom: 32px;
      h1 {
        font-size: 24px;
      }
      p {
        line-height: 22px;
      }
      a {
        font-weight: bold;
      }
    }

    &.two-columns {
      width: 100%;
      max-width: 960px;
    }
    .panel-wrap {
      display: table;
      width: 100%;
    }

    .sign-in-wrap {
      display: table-cell;
      vertical-align: middle;
    }

    .announcement {
      display: table-cell;
      padding-right: 60px;
      width: 480px;
      h2 {
        text-transform: uppercase;
        font-size: 14px;
      }
      a {
        &:hover, &:active, &:focus {
          text-decoration: none;
        }
      }
      .img {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 200px;
      }
      h3 {
        font-size: 16px;
        line-height: 24px;
      }
      p {
        display: block;
        width: 360px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #999;
      }
    }

    label {
      display: block;
    }
    .form-control-group {
      margin-bottom: 16px;
      width: 100%;
      position: relative;
      &.submit {
        margin-top: 24px;
        button {
          display: block;
          width: 100%;
        }
        .btn {
          .border-radius(4px);
        }
      }
      .tip {
        position: absolute;
        top: 55%;
        right: 8px;
        font-size: 13px;
        color: #999;
        padding: 12px auto;
        &:hover, &:active, &:focus {
          text-decoration: none;
          color: #333
        }
      }
      .input-tip {
        font-size: 13px;
        color: #999;
        margin-top: 12px;
        padding: 12px auto;
      }
    }
    .form-control {
      width: 100%;
      padding: 8px 16px;
      font-size: 16px;
      height: 48px;
      .border-radius(4px);
    }
    .auth-logo {
      text-align: center;
      margin-bottom: 0;
      width: 100%;
      clear: both;
      .panel-image {
        display: block;
        height: 72px;
        width: 72px;
        .border-radius(12px);
        margin: 0 auto
      }
    }
    .other-actions {
      text-align: center;
      margin-top: 32px;
    }
    .minor {
      color: @gray
    }
    &.success {
      background-color: @brand-success;
      color: #fff;
      .auth-logo {
        .icon {
          font-size: 120px;
        }
      }
      .panel-header {
        p {
          color: #fff;
        }
      }
    }
  }

  .or-split {
    margin: 32px 0;
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: center;
  }
  .or-text {
    display: inline-block;
    margin-top: -9px;
    height: 16px;
    background-color: #fff;
    position: absolute;
    width: 60px;
    margin-left: -30px;
    color: #999;
    text-transform: uppercase;
    font-size: 12px;
    .responsive(@screen-tablet, {
      background-color: #F3F3F7
    })
  }

  .slack-command-text {
    line-height: 26px;
  }

  .home-logo {
    .replace_text_width_image('../img/logo/logo-borderless.svg', 285px, 46px);
  }

  .input-email, .input-submit {
    .responsive(@screen-phone, { display: block; width: 90% !important; .border-radius(6px) !important; border-width: 1px !important; margin-bottom: 12px !important; })
  }

  .auth-sign-up {
    .form-control-group.submit {
      margin-top: 0;
    }
  }

  .error {
    text-align: center;
    .code {
      font-size: 160px;
      line-height: 200px;
      font-weight: 300;
    }
    .instructions {
      margin: 72px 0 32px 0
    }
  }

  .responsive(@screen-tablet, {

    .auth-panel {
      border:0;
      background: transparent;
      box-shadow: none;
      .panel-wrap {
        display: block;
      }
      .announcement {
        display: none
      }
      .sign-in-wrap {
        display: block;
      }
    }
  });

  /* The snackbar - position it at the bottom and in the middle of the screen */
  #snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: @brand-danger; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 4px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    top: 30px; /* 30px from the bottom */
  }

  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  #snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 30px; opacity: 1;}
  }

  @keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 30px; opacity: 1;}
  }

  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  .btn-integration {
    background-color: #FAFAFC;
    border: 1px solid #D5D5D9;
    color: #333;
    width: 100%;
    padding: 12px 0;
    margin-bottom: 12px;
    .border-radius(4px);
    display: inline-block;
    text-align: center;
    transition: all .1s linear;
    font-weight: 400;

    &:hover, &:focus {
      text-decoration: none;
      background-color: #F3F3F7;
      color: #111;
    }
    &:active {
      background-color: #EBE9ED;
    }
  }

  .input-field {
    background-color: #fff;
    border: 1px solid #D5D5D9;
    color: #333;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 12px;
    .border-radius(4px);
    display: inline-block;
    text-align: left;
    transition: all .1s linear;
    font-weight: 400;
    &:hover {
      border-color: lighten(@brand-primary, 30%);
    }
    &:focus {
      outline-color: lighten(@brand-primary, 10%);
    }
  }

  .icon {
    .square(24px);
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
    background-size: cover;
    &.slack {
      background-image: url(../img/logo/slack.svg);
    }
    &.google {
      background-image: url(../img/logo/google.svg);
    }
  }

  .body-loading {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loading-text {
    text-align: center;
    color: #333;
    font-size: 12px;
    font-weight: 300;
    margin-top: 32px;
  }

  .loader-wrap {
    margin: 100px auto 0;
    text-align: center;
  }

  .loader {
    width: 64px;
    height: 64px;
    border: 4px solid #EBE9ED; /* Light grey */
    border-top: 4px solid #00C4DB; /* Blue */
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
    display: inline-block;
    vertical-align: middle;
  }

  .modal-body .body-loading {
    position: relative;
    .box-shadow(none);
    transform: none;
    top: 0;
    left: 0;
    background-color: transparent;
    text-align: center;
  }

  .how-it-works-features {
    padding: 0;
    margin-top: 32px;
    list-style-type: none;
    li {
      font-size: 20px;
      margin: 10px 0;
      line-height: 32px;
      display: inline-block;
      &:hover {
        background-color: rgba(255, 255, 0, 0.4);
      }
    }
    .number {
      border: 2px solid #333;
      vertical-align: middle;
      display: inline-block;
      .circle(32px);
      line-height: 28px;
      font-size: 16px;
      text-align: center;
      position: absolute;
      margin-left: -42px;
    }
  }

  .language-selector {
     position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0,0,0,.1);
    transition: box-shadow .1s linear;
    border-radius: 30px;
    padding: 16px 20px;
    box-sizing: border-box;
    font-size: 13px;
    color: #666;
    cursor: pointer;
    &:hover {
       box-shadow: 0 10px 20px rgba(0,0,0,.2);
    }
  }

  .language {
    display: block;
    padding: 12px;
    transition: background-color .1s linear;
    &:hover {
      cursor: pointer;
      background-color: #F3F3F7;
    }
  }
}
