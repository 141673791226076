.form-input-wrap{
  max-width:600px;
  width:100%;
}

.form{
  label{
    margin-bottom:12px;
    .alert{
      color:@brand-danger;
      background:transparent;
    }
  }
  .form-group{
    margin-bottom:24px;
  }
  .alert{
    font-size:12px;
    border:0;
    margin:8px 0 0 0;
  }
  .tip{
    font-size:12px;
    padding-top:8px;
    margin:0;
  }
  .submit{
    .clearfix();
  }
}
.center-panel{
  .form{
    .tip{
      color:#fff;
    }
  }
}

.input-row{
  &.label{
    margin-bottom:18px;
    label {
      .alert{
        margin-left: 8px;
      }
    }
  }
}
